/* Poppins font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@800&family=Poppins&family=Roboto&family=Ubuntu&display=swap");

.App {
  font-family: "Poppins", sans-serif;
}

.navbar {
  background-color: #a7defc;
}

.section {
  padding-top: 80px;
}

.section2 {
  padding-top: 30px;
}

.heading {
  font-size: 20px;
  font-weight: bold;
}

.subheading {
  font-size: 16px;
  font-weight: bold;
  color: #818589;
}

.alt-text {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-style: italic;
}

.parent {
  padding-bottom: 10px;
}

#image {
  width: 60%;
  height: 60%;
  margin-top: 12px;
}

p {
  margin: 0px 0px 0px 0px;
}

.line {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}
